import { createTheme, ThemeProvider } from '@material-ui/core/styles';

export const THEME = createTheme({
  breakpoints: {
    keys: [
        'xs',
        'sm',
        'md',
        'lg',
        'xl'
    ],
    values: {
        xs: 360,
        sm: 640,
        md: 1024,
        lg: 1280,
        xl: 1440
    }
  },
  palette: {
    primary: {
      main: '#00A88E',
    },
    secondary: {
      main: '#CCCCCC',
    },
  },
  typography: {
    fontFamily: 'KapserksySans-500, Helvetica, Arial, sans-serif',
    h1: {
      fontFamily: 'KapserksySans-700, Helvetica, Arial, sans-serif',
      fontWeight: 'bold',
      letterSpacing: '-0.01em'
    },
    h2: {
      fontFamily: 'KapserksySans-700, Helvetica, Arial, sans-serif',
      fontWeight: 'bold',
    },
    button: {
      textTransform: 'inherit'
    },
  },
  overrides: {
    MuiContainer: {
      root: {
        paddingLeft: "16px",
        paddingRight: "16px",
        "@media only screen and (min-width: 640px)": {
          paddingLeft: "32px",
          paddingRight: "32px",
        }
      }
    },
    MuiTypography: {
      body1: {
        fontFamily: 'KapserksySans-300, Helvetica, Arial, sans-serif',
        fontSize: '16px',
        lineHeight: '20px'
      },
      h2: {
        fontFamily: 'KapserksySans-500, Helvetica, Arial, sans-serif',
        fontWeight: 'bold',
        letterSpacing: '0.03em !important'
      }
    },
    MuiButton: {
      root: {
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0)',
        },
      },
      iconSizeSmall: {
        '& > *:first-child': {
          fontSize: 10,
          color: '#AEAEAE'
        }
      },
      containedPrimary: {
        '&:disabled': {
          // background: '#00A88E !important',
          // border: 'none !important',
          '& .MuiButton-label': {
            color: '#FFFFFF !important',
            opacity: 0.64
          },
          '& svg path': {
            fill: '#FFFFFF'
          }
        }
      },
      outlinedSecondary: {
        color: '#444444 !important'
      },
      textPrimary: {
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0)',
        },
      }
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: 'rgba(255, 255, 255, 1)',
        color: 'rgba(102, 102, 102, 1)',
        boxShadow: '0px 4px 20px rgba(92, 133, 150, 0.24)',
        fontSize: '12px',
        lineHeight: '16px',
        padding: '11px 12px',
        borderRadius: '3px'
      },
    },
    MuiInput: {
      root: {
        width: '100%',
      },
      underline: {
        '&:before': {
          borderBottom: '2px solid #CCCCCC'
        },
        '&:hover': {
          '&::before': {
            borderBottom: '2px solid #23D1AE !important',
          }
        }
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        border: '2px solid #CCCCCC',
        transition: 'all ease 0.25s',
      },
      root: {
        '&:hover $notchedOutline': {
          border: '2px solid #BDE0F5 !important',
        },
        '&:active $notchedOutline, &$focused $notchedOutline': {
          border: '2px solid #CCCCCC !important',
          borderBottom: '2px solid #23D1AE !important',
        }
      }
    },
    MuiFormControlLabel: {
      root: {
        fontFamily: '"KapserksySans-700", sans-serif',
        color: '#AEAEAE',
        fontSize: '28px',
        marginLeft: '0',
      }
    },
    MuiCheckbox: {
      root: {
        padding: 0,
        marginRight: '8px',
        borderRadius: '3px',
        backgroundColor: '#FFFFFF',
        width: '24px',
        height: '24px',
      },
    }
  },
  shadows: ['none', '0px 2.93803px 14.6902px rgba(92, 133, 150, 0.24)'],
  shape: {
    borderRadius: 0
  },
});

export default THEME;

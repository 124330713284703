// @flow

// Libraries
import React, { Element } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { get } from 'lodash';

// Components
import {
  CardActions
} from '@material-ui/core';

import Button from '@components/Button';
import Modal from '@components/Modal';
import Autocomplete from '@components/Autocomplete';

// Hooks
import useActions from './hooks';

// Types
import { VARIANT, MODE, TYPE } from './data';

// Styles
import styles from './Card.scss';

// Icons
import { ReactComponent as DeleteIcon } from '@assets/icons/delete_red.svg';
import { ReactComponent as LinkIcon } from '@assets/icons/link.svg';
import {loader} from "graphql.macro";
import {useLazyQuery} from "react-apollo";

export type PropTypes = {
  className?: String,
  classes?: {
    root?: String,
    media?: String,
    content?: String,
    title?: String,
    additional?: String,
    description?: String,
  },
  variant?: typeof VARIANT.HORISONTAL | typeof VARIANT.VERTICAL,
  mode?: typeof MODE.READ | typeof MODE.EDIT,
  type?:
    typeof TYPE.TEXT
    | typeof TYPE.LINK
    | typeof TYPE.ASSET
    | typeof TYPE.COLLECTION,
  data?: Object,
  count?: number
};

const query = loader('src/graphql/queries/getAsset.gql');

export const Actions = ({
  className,
  classes = {},
  variant = VARIANT.VERTICAL,
  mode = MODE.READ,
  type = TYPE.LINK,
  count,
  ...props
}: PropTypes): Element<CardActions> => {
  const {
    data,
    loadingGetAssets,
    selected, setSelected,
    isModal, setIsModal,
    assets,
    handleInput,
    handleUpdate,
    handleAdd,
    handleRemove,
    handleChange,
    handleFocus,
    handleBlur,
  } = useActions(props);

  const id = get(data, 'id');
  const slug = get(data, 'slug');

  const [getAsset] = useLazyQuery(query, {
    variables: {id},
    onCompleted: (res) => {
      if (res) {
        window.open(res.asset.accessUrl, '_blank');
      }
    },
    fetchPolicy: 'no-cache',
    onError: error => {}
  });

  const isLogin = useSelector(state => !!get(state, 'session.token', false));

  const onClick = (e) => {
    if (isLogin) {
      e.stopPropagation();
      e.preventDefault();
      getAsset();
    } else {
      return;
    }
  }

  const onClickView = (e) => {
    window.open(
      `/portal/${type}/${id}`,
      '_blank' // <- This is what makes it open in a new window.
    );
  }

  switch (type) {
    case TYPE.ASSET:
    case TYPE.COLLECTION:
      return mode === MODE.EDIT
          ? (
            <CardActions
              className={styles.Actions}
            >
              <Button
                classes={{root: styles.ButtonAdd}}
                variant={variant === VARIANT.HORISONTAL ? 'outlined' : 'text'}
                color="secondary"
                startIcon={<LinkIcon />}
                onClick={handleAdd}
              >
                Add link to asset
              </Button>
              <Button
                classes={{root: styles.ButtonRemove}}
                variant={variant === VARIANT.HORISONTAL ? 'outlined' : 'text'}
                color="secondary"
                startIcon={<DeleteIcon />}
                onClick={handleRemove}
              >
                Remove card
              </Button>
              <Modal
                classes={{
                  container: styles.ModalContainer,
                  content: styles.ModalContent
                }}
                title="Add link to asset"
                onClose={(event) => { setIsModal(false); }}
                open={isModal}
                maxWidth="md"
                fullWidth
              >
                <Autocomplete
                  className={styles.Autocomplete}
                  options={assets}
                  getOptionLabel={(item) => item.title}
                  freeSolo
                  disableClearable
                  loading={loadingGetAssets}
                  onInputChange={handleInput}
                  onChange={handleChange}
                  name="asset"
                  type="text"
                  variant="outlined"
                  value={selected}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  placeholder="Search by asset name or paste a link to asset"
                />
                <Button
                  className={styles.ModalButton}
                  variant="contained"
                  onClick={handleUpdate}
                >
                  Add asset
                </Button>
              </Modal>
            </CardActions>
          )
          : (
            <CardActions
              className={classNames({
                [styles.Actions]: true,
                [styles.Centered]: !isLogin
              })}
            >
              <Button
                classes={{root: styles.ButtonDownload}}
                variant="contained"
                onClick={e => onClick(e)}
                disabled={!isLogin}
              >
                Download {count ? `(${count})` : null}
              </Button>
              <Button
                classes={{root: styles.ButtonView}}
                variant="outlined"
                onClick={onClickView}
              >
                View
                {' '}
                {type}
              </Button>
            </CardActions>
          );
    case TYPE.LINK:
      return (
        <CardActions
          className={styles.Actions}
        >
          <Button
            classes={{root: styles.ButtonLink}}
            // size="small"
            to={`/wiki/${slug}`}
          />
        </CardActions>
      );
    default:
      return null;
  };
};

export default Actions;

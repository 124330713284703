// @flow

// Libraries
import React, { Element, useState } from 'react';
import classNames from 'classnames';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { get, isFunction } from 'lodash';

// Components
import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Icon from '@material-ui/core/Icon';

import Search from '@views/Search/Search';
import Button from '@components/Button';
import Sandwich from '@components/Sandwich/Sandwich';

import Menu from '../Menu/Menu';
import Logo from '../Logo';

import Favorites from './Favorites';
import Recent from './Recent';

// Data
// import {WYSIWYG_menu} from './menuCollections';

// Services
import { updateSession } from '@services/session';

// Styles
import styles from './Header.scss';

// Icons
import { ReactComponent as UserIcon } from '@assets/icons/user.svg';

// Types
type HeaderTypes = {
  className?: String,
  isHideNav?: Boolean,
  isLogin?: Boolean,
  isHasSandwich?: Boolean,
  isEdit?: Boolean,
  role?: string,
  updateSession?: Function,
  actionPanel?: any,
  onMode?: Function,
  menuItems?: Object
};

const MENU_ITEMS = {
  default: [
    {
      title: 'Learn',
      wrapped: true,
      link: '/wiki'
    },
    {
      title: 'Downloads',
      wrapped: false,
      link: '/portal',
    },
    {
      title: 'About',
      wrapped: false,
      link: '/portal/longread/132',
    }
  ],
  login: [
    {
      title: 'Learn',
      wrapped: true,
      link: '/wiki'
    },
    {
      title: 'Downloads',
      wrapped: false,
      link: '/portal',
    },
    // {
    //   title: 'Contact us',
    //   wrapped: false,
    //   link: 'mailto:brand@kaspersky.com',
    // },
    {
      title: 'About',
      wrapped: false,
      link: '/portal/longread/132',
    }
  ]
};

// eslint-disable-next-line complexity
const Header = ({
  className,
  isHideNav,
  isLogin,
  isHasSandwich,
  isEdit,
  role,
  updateSession,
  actionPanel,
  onMode,
  ...props
}: HeaderTypes): Element<AppBar> => {
  const [isOpenSandwich, setOpenSandwich] = useState(false);
  const history = useHistory();
  // const menuItems = get(props, 'menuItems', MENU_ITEMS[isLogin ? 'login' : 'default']);
  const menuItemsVertical = get(props, 'menuItems.vertical', MENU_ITEMS[isLogin ? 'login' : 'default']);
  const menuItemsHorizontal = get(props, 'menuItems.horizontal', MENU_ITEMS[isLogin ? 'login' : 'default']);
  return (
    <AppBar
      position="static"
      color="transparent"
      elevation={0}
      data-is-login={isLogin}
      data-is-open-sandwich={isOpenSandwich}
      className={classNames(
        className,
        styles.Root,
        {
          [styles.RootWithShadow]: !isHideNav,
          [styles.RootCenteredLogo]: isHideNav
        }
      )}
    >
      <Container className={styles.Limiter} maxWidth="lg">
        <Toolbar classes={{ root: isLogin ? styles.ToolbarLogin : styles.Toolbar}} disableGutters>
          {
            isHasSandwich && menuItemsVertical.length > 0 && (
              <Sandwich
                isOpenSandwich={isOpenSandwich}
                setOpenSandwich={setOpenSandwich}
              />
            )
          }
          {
            // isLogin
            //   ?
            <Link to='./' className={styles.BrandPortalLogo}>Brand Portal</Link>
              // :
              //   <Logo />
          }
          {
            <Menu
              classes={{root: classNames({
                [styles.DesktopMenu]: true,
                [styles.isLogin]: isLogin
              })}}
              items={menuItemsHorizontal}
              orientation='horizontal'
            />
          }
          {
            !isHideNav && (
              <div className={styles.Container}>
                {
                  menuItemsVertical.length > 0 && isOpenSandwich && (
                    <>
                      <div
                        className={styles.BackgroundMobileMenu}
                        onClick={(): void => setOpenSandwich(false)}
                      />
                      <div
                        className={styles.ContainerMobileMenu}
                        onClick={(): void => setOpenSandwich(false)}
                      >
                        <Menu
                          classes={{root: styles.MobileMenu}}
                          items={menuItemsVertical}
                          orientation='vertical'
                          isLogin={isLogin}
                        />
                      </div>
                    </>
                  )
                }
                {
                  actionPanel ? actionPanel : (
                    <>
                      {/* <Search classnames={{root: isLogin ? styles.SearchLog : styles.Search}} hide isLogin={isLogin} /> */}
                      {
                        isLogin && (
                        <>
                          {(role === 'admin' || role === 'editor') && (
                            <label
                              className={styles.EditMode}
                              htmlFor="edit_mode_checkbox"
                            >
                              <input
                                id="edit_mode_checkbox"
                                name="edit_mode"
                                type="checkbox"
                                checked={isEdit}
                                onChange={(): void => updateSession({ isEdit: !isEdit }) && isFunction(onMode) && onMode(!isEdit)}
                              />
                              <i />
                              Edit mode
                            </label>
                          )}

                          {/* <Button
                            size="small"
                            startIcon={<Lightbox />}
                            className={styles.LightboxButton}
                          >
                            <span className={styles.ButtonText}>Lightbox</span>
                          </Button>

                          <Button
                            size="small"
                            startIcon={<Backup />}
                            className={styles.BackUp}
                          >
                            <span className={styles.ButtonText}>Recent</span>
                          </Button> */}
                          {isLogin && <><Favorites /><Recent /></>}
                        </>
                        )
                      }
                      <Button
                        size="small"
                        startIcon={<UserIcon className={styles.UserSignInIcon} />}
                        onClick={() => {
                          window.location = '/portal/profile';
                        }}
                        className={isLogin ? styles.UserProfile : styles.UserSignIn}
                      >
                        {
                          isLogin ?
                          <span className={styles.ButtonText}>Profile</span> :
                          <span className={styles.ButtonText}>Sign in</span>
                        }
                      </Button>
                    </>
                  )
                }
              </div>
            )
          }
        </Toolbar>
      </Container>
    </AppBar>
    // <div className={Menu.scss.Content}>
    //   <div className={Menu.scss.LeftSide}>
    //     <Logo />
    //     <Menu navList={WYSIWYG_menu} />
    //   </div>
    //   <div className={Menu.scss.RightSide}>2</div>
    // </div>
    // </div>
  )
};

const mapStateToProps = (state: Object): Object => ({
  isEdit: get(state, 'session.isEdit', false),
  isLogin: get(state, 'session.token', false),
  role: get(state, 'session.role', ''),
});

const mapDispatchToProps = (dispatch) => ({
  // Services
  updateSession: (data) => dispatch(updateSession(data))
});

// Exports
export default compose(connect(mapStateToProps, mapDispatchToProps))(Header);

// @flow

// Libraries
import React, { Element } from 'react';
import classNames from 'classnames';
import { get, upperFirst, startCase } from 'lodash';
import parse from 'html-react-parser';
import moment from 'moment';

// Components
import {
  Card as BaseCard,
  CardHeader,
  CardContent,
  CardMedia,
} from '@material-ui/core';

// Icons
import AttachFileIcon from '@material-ui/icons/AttachFile';

import { H2, H6, Subtitle, Caption } from '@components/Typography';

import { Actions } from './Actions';

// Styles
import styles from './Card.scss';

// Types
import { VARIANT, MODE, TYPE } from './data';

export type PropTypes = {
  className?: String,
  classes?: {
    root?: String,
    media?: String,
    content?: String,
    title?: String,
    additional?: String,
    description?: String,
  },
  variant?: typeof VARIANT.HORISONTAL | typeof VARIANT.VERTICAL,
  mode?: typeof MODE.READ | typeof MODE.EDIT,
  type?:
    typeof TYPE.TEXT
    | typeof TYPE.LINK
    | typeof TYPE.ASSET
    | typeof TYPE.COLLECTION,
  data?: Object,
  onFocus?: Function,
  onBlur?: Function,
  onUpdate?: Function,
  onRemove?: Function,
  lastUpdate?: String,
  collectionCount?: Number
};

export const Card = ({
  className,
  classes = {},
  variant = VARIANT.VERTICAL,
  mode = MODE.READ,
  type = TYPE.LINK,
  data,
  onFocus,
  onBlur,
  onUpdate,
  onRemove,
  lastUpdate,
  collectionCount,
  ...props
}: PropTypes): Element<BaseCard> => {
  const id = get(data, 'id');
  const title = parse(get(data, 'title') || '');
  const name = parse(get(data, 'name') || '');
  const additional = get(data, 'additional');
  const description = parse(get(data, 'description') || '');
  const preview = get(data, 'preview');
  const slug = get(data, 'slug');
  const link = get(data, 'link');

  return (
    <BaseCard
      className={classNames(
        className,
        classes?.root,
        styles.Root,
        {
          [styles.RootVariantHorisontal]: variant === VARIANT.HORISONTAL,
          [styles.RootVariantVertical]: variant === VARIANT.VERTICAL
        },
        {
          [styles.RootModeRead]: mode === MODE.READ,
          [styles.RootModeEdit]: mode === MODE.EDIT
        },
        {
          [styles.RootTypeText]: type === TYPE.TEXT,
          [styles.RootTypeLink]: type === TYPE.LINK,
          [styles.RootTypeAsset]: type === TYPE.ASSET,
          [styles.RootTypeCollection]: type === TYPE.COLLECTION,
        }
      )}
      {...(mode !== MODE.EDIT && link && {
        component: 'a',
        href: link
      })}
    >
      {
        mode === MODE.EDIT && type === TYPE.LINK && (
          <CardHeader
            className={classNames(
              classes?.header,
              styles.Header
            )}
            // action="..."
          />
        )
      }
      <CardMedia
        className={classNames(
          classes?.media,
          styles.Media
        )}
      >
        <img alt={title} title={title} src={preview} />
      </CardMedia>
      <CardContent
        className={classNames(
          classes?.content,
          styles.Content,
          {[styles.description]: !!description.length}
        )}
      >
        <TopPanel count={collectionCount} lastUpdate={lastUpdate} />
        {/* {
          name && (
            <H6
              className={classNames(
                classes?.name,
                styles.Name,
              )}
            >
              {upperFirst(startCase(name).toLowerCase())}
            </H6>
          )
        } */}
        {
          title && (
            <H2
              className={classNames(
                'Test',
                classes?.title,
                styles.Title,
              )}
            >
              {title}
            </H2>
          )
        }
        {
          additional && (
            <Caption
              className={classNames(
                classes?.additional,
                styles.Additional
              )}
            >
              {additional}
            </Caption>
          )
        }
        {
          description && (
            <Subtitle
              className={classNames(
                classes?.description,
                styles.Description
              )}
            >
              {description}
            </Subtitle>
          )
        }
      </CardContent>
      <Actions
        data={data}
        type={type}
        mode={mode}
        variant={variant}
        onFocus={onFocus}
        onBlur={onBlur}
        onUpdate={onUpdate}
        onRemove={onRemove}
        count={collectionCount}
      />
    </BaseCard>
  )
};

const TopPanel = ({
  count,
  lastUpdate,
}) => {
  return (
    <div className={styles.TopPanel}>
      {count ? (
        <div className={styles.Collection}>
          <span>Collection</span>
            <span className={styles.Dot}>&#8226;</span>
            <span className={styles.Icon}>
              <AttachFileIcon />
            </span>
            <span className={styles.Count}>{count}</span>
        </div>
      ) : null}
      {
        lastUpdate && (
          <div className={styles.LastUpdate}>
            Last update: {moment(lastUpdate).format('DD/MM/YYYY')}
          </div>
        )
      }
    </div>
  );
};

export default Card;
